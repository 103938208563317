import TempPost1 from 'common/images/temp-post1 - Copy.jpg';
import { PreviewPostProps } from 'common/types/PostProps';

export const mockBigPost: PreviewPostProps = {
  id: '6',
  title: 'Система шкіл у Німеччині',
  text: 'Багато сімей, рятуючи найперше своїх дітей від повномасштабної війни, переїхали з України закордон, а зокрема в Німеччину. І батьки, не гаючи часу, віддають дітей на навчання до німецьких шкіл, для того, щоби дитина здобувала крім української ще й європейську освіту. І це дуже правильне рішення. Бо чим більш універсальну освіту дитина матиме, тим легше їй у майбутньому буде адаптуватися в будь-якій к...',
  category: 'Психологія',
  date: '2023-11-15',
  image: TempPost1,
};
