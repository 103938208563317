import { useTranslation } from 'react-i18next';
import useFetch from 'common/hooks/useFetch';

export const useFetchPreviewPost = () => {
  const { i18n } = useTranslation();
  const { response, loading, error } = useFetch('api/article/get-article-preview',
    {
      method: 'GET',
      useEnvLink: true,
      params: {
        lang: i18n.language,
      },
      updateList: [i18n.language],
    },
  );
  return {
    response: {
      id: response?.articlePreview?.id || '',
      title: response?.articlePreview?.title || '',
      text: response?.articlePreview?.text || '',
      category: response?.articlePreview?.category || '',
      date: response?.articlePreview?.date || '',
      image: response?.articlePreview?.image || '',
    },
    loading,
    error,
  };
};
