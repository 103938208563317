import AboutPage from 'common/pages/AboutPage';
import BaseLayout from 'common/pages/BaseLayout';
import ContactsPage from 'common/pages/ContactsPage';
import MainPage from 'common/pages/MainPage';
import PageNotFound from 'common/pages/PageNotFound';
import PostPage from 'common/pages/PostPage';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';




export default function CustomRouter() {
  return (
		<BrowserRouter>
			<Routes>
				<Route element={<BaseLayout />} >
					<Route path="/" element={<MainPage />} />
					<Route path="/about" element={<AboutPage />} />
					<Route path="/contacts" element={<ContactsPage />} />
					<Route path="/article/:id" element={<PostPage />} />
					<Route element={<PageNotFound />} path='*' />
				</Route>
			</Routes>
		</BrowserRouter>
  );
}