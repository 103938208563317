// eslint-disable-next-line no-unused-vars

import React, { Suspense } from 'react';
import CustomRouter from './setup/Router';
import { Global, css } from '@emotion/react';
import { LinearProgress, createTheme } from '@mui/material';
import './css/global.scss';
import './css/_mixins.scss';
import './css/_variables.scss';
import './fonts/Manrope/Manrope-Font.css';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import lightThemeData from 'common/data/lightThemeData';
import 'i18n';
import useFetch from 'common/hooks/useFetch';


const theme = createTheme(lightThemeData);


function App() {
  const globalStyles = css`
    body {
      transition: color .3s ease-out, background-color .3s ease-out, background .3s ease-out;
    }
    span, p, h1, h2, h3, h4, h5, h6, a, button, input, label, div, li, ul, ol, textarea {
      
    }
    .error-text {
      color: ${theme.colors.error};
    }
    body {
      background-color: ${theme.colors.backgroundColor};
      color: ${theme.colors.primaryTextColor};
    }
    .gray-text {
      color: ${theme.colors.gray};
    }
    .error-text {
      color: ${theme.colors.error};
    }
    .svg-black {
      stroke: ${theme.colors.black};
    }

    .svg-fill {
      stroke: unset;
      fill: ${theme.colors.black};
    }
    .svg-none {
      stroke: inherit;
      fill: inherit;
    }
    .svg-primary-stroke {
      stroke: ${theme.colors.primaryColor};
    }
    .svg-primary-fill {
      fill: ${theme.colors.primaryColor};
    }
    .svg-button {
      cursor: pointer;
      transition: stroke .3s ease-out;
    }
    .svg-fill.svg-button {
      cursor: pointer;
      transition: fill .3s ease-out;
    }
    .svg-button:hover, .svg-button.active {
      stroke: ${theme.colors.primaryColor};
    }
    .svg-fill.svg-button:hover, .svg-fill.svg-button.active {
      stroke: unset;
      fill: ${theme.colors.primaryColor};
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        transition: background-color 9999s ease-in-out 0s;
    }
  `;

  const { response } = useFetch('weatherforecast', {
    method: 'GET',
    useEnvLink: true,
  });
  console.log(response);
  return (
    <Suspense fallback={<div className='loading-wrapper'><LinearProgress sx={{
      color: '#AB1D1D',
      height: '5px',
      width: '100%',
      position: 'absolute',
      top: '0',
      left: '0',
      '& .MuiLinearProgress-bar': {
        backgroundColor: '#AB1D1D',
      },
    }} /></div>} >
      <ThemeProvider theme={theme}>
          <Global
            styles={globalStyles}
          />
          <CustomRouter />
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
