export const categories = [
  {
    labelKey: 'categories.psychology',
    id: 'psychology',
  },
  {
    labelKey: 'categories.work',
    id: 'work',
  },
  {
    labelKey: 'categories.interview',
    id: 'interview',
  },
  {
    labelKey: 'categories.health',
    id: 'health',
  },
  {
    labelKey: 'categories.children',
    id: 'children',
  },
  {
    labelKey: 'categories.moving',
    id: 'moving',
  },
  {
    labelKey: 'categories.podcasts',
    id: 'podcasts',
  },
];
