import DotSeparator from 'common/components/DotSeparator';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ReactComponent as ArrowLeft } from 'common/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'common/icons/arrow-right.svg';
import './postPage.scss';
import JournalSection from 'common/components/JournalSection';
/*import { ReactComponent as FacebookIcon } from 'common/icons/social/facebook.svg';
import { ReactComponent as InstagramIcon } from 'common/icons/social/instagram.svg';
import { ReactComponent as TwitterIcon } from 'common/icons/social/twitter.svg';
import { ReactComponent as TelegramIcon } from 'common/icons/social/telegram.svg';*/
import { PostProps } from 'common/types/PostProps';
import { CarouselItem } from 'common/components/CarouselItem';
import { posts } from 'common/data/posts';
import { mockPost } from 'common/data/mockPost';
import { useFetchPost } from 'common/hooks/useFetchPost';
import TempPost1 from 'common/images/temp-post1.jpg';
import TempPost2 from 'common/images/temp-post2.jpg';
import TempPost3 from 'common/images/temp-post3.jpg';
import TempPost4 from 'common/images/temp-post4.jpg';
//import TempPost5 from 'common/images/temp-post5.jpg';
import TempPost6 from 'common/images/temp-post6.jpg';
import TempPost7 from 'common/images/temp-post7.jpg';

export default function PostPage() {
  const [post] = useState<PostProps>(mockPost);
  const mockImages = { '1': TempPost6, '2': TempPost2, '3': TempPost3, '4': TempPost4, '5': TempPost7, '6': TempPost1 };
  //const navigate = useNavigate();
  // get post id from url
  const { id } = useParams();
  console.log(id);
  const { t } = useTranslation();
  const swiperRef = useRef(null);
  const handlePrev = useCallback(() => {
    if (!swiperRef.current) return;
    swiperRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!swiperRef.current) return;
    swiperRef.current.swiper.slideNext();
  }, []);
  // TODO: get post data from api
  const { response: postResponse, error:postError, loading:postLoading } = useFetchPost(id);
  console.log(postResponse);
  console.log(postError);
  console.log(postLoading);


  // TODO: uncomment this
  /*
  if (postError) {
    navigate('/404');
  }
  */

  return (
    <div className='post-page'>
      <div className='small-post-time-wrapper'>
        <span className='main-post-subtext'>{t('categories.children')}</span>
        <DotSeparator />
        <span className='main-post-subtext'>
          {t('intlDateTime', {
            val: (postResponse?.date && new Date(postResponse.date)) || new Date('2023-11-15'),
            formatParams: {
              val: {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              },
            },
          })}
        </span>
      </div>
      <div className='post-title'>
        <h1>{postResponse.title || t('mockPost' + id + '.title')}</h1>
      </div>
      <div className='post-image-wrapper'>
        <img src={postResponse.image || mockImages[id]} alt='post' />
      </div>
      <span className='post-subtext'>
        <span className='post-subtext'>{t('post.source')}: </span>
        <a className='post-subtext' href={postResponse.source || post.source} target='_blank' rel='noreferrer'>
          {postResponse.source || post.source}
        </a>
      </span>
      <div className='post-content-wrapper'>
        <div className='post-socials-wrapper'>
          {/*<a target='_blank' href=''><FacebookIcon className='svg-black svg-fill' /></a>
          <a target='_blank' href=''><InstagramIcon className='svg-black svg-fill' /></a>
          <a target='_blank' href=''><TwitterIcon className='svg-black svg-fill' /></a>
          <a target='_blank' href=''><TelegramIcon className='svg-black svg-fill' /></a>*/}
        </div>
        <div className='post-content'>
          <ReactMarkdown
            className='post-text'
          >
            {postResponse.text || t('mockPost' + id + '.text')}
          </ReactMarkdown>
          <div className='post-tags'>
            <div className='post-tag'>
              {postResponse.category || t('mockPost' + id + '.category')}
            </div>
            {postResponse?.tags.length > 0 ?
              postResponse.tags.map((tag, index) => (
              <div key={index} className='post-tag'>
                {tag.name}
              </div>
              )) :
              post.tags.map((tag, index) => (
              <div key={index} className='post-tag'>
                {t(`mockPost${id}.tags.${tag.id}`)}
              </div>
              ))}
          </div>
        </div>
        <div className='post-socials-wrapper' />
      </div>
      <div className='post-separator' />
      <div className='post-swiper-wrapper'>
        <div className='post-swiper-title-wrapper'>
          <span>{t('post.otherPosts')}</span>
        </div>
        <div className='post-carousel-wrapper'>
          <button
            className='post-carousel-button post-carousel-button-prev'
            onClick={handlePrev}
          >
            <ArrowLeft className='svg-black' />
          </button>
          <Swiper
            slidesPerView={1}
            spaceBetween={16}
            rewind={true}
            breakpoints={{
              1440: {
                slidesPerView: 3,
              },
              920: {
                slidesPerView: 2,
              },
              640: {
                slidesPerView: 1,
              },
            }}
            ref={swiperRef}
            centeredSlides={false}
            centerInsufficientSlides
            className='post-carousel'
          >
            {
              posts.map((postRender, index) => (
                <SwiperSlide key={index} className='post-carousel-slide-wrapper'>
                  <CarouselItem {...{ ...postRender, ...{ title: t(`mockPosts.${postRender.id}.title`), category: t(`mockPosts.${postRender.id}.category`) } }} />
                </SwiperSlide>
              ))
            }

          </Swiper>
          <button
            className='post-carousel-button post-carousel-button-next'
            onClick={handleNext}
          >
            <ArrowRight className='svg-black' />
          </button>
        </div>
      </div>
      <JournalSection />
    </div>
  );
}
