import { useTranslation } from 'react-i18next';
import useFetch from 'common/hooks/useFetch';

export const useFetchPostsByCategory = (category: string) => {
  const { i18n } = useTranslation();
  const { response, loading, error } = useFetch('api/article/get-article-view-by-category-id',
    {
      method: 'GET',
      useEnvLink: true,
      params: {
        lang: i18n.language,
        categoryId: category,
        count: 6,
      },
      updateList: [category, i18n.language],
    },
  );
  return {
    response: response?.articleList || [],
    loading,
    error,
  };
};
