import React from 'react';
import './footer.scss';
import { ReactComponent as FooterLogo } from 'common/icons/branding/logo-footer.svg';
import { Link } from 'react-router-dom';
/*import { ReactComponent as FacebookIcon } from 'common/icons/social/facebook.svg';
import { ReactComponent as InstagramIcon } from 'common/icons/social/instagram.svg';
import { ReactComponent as TwitterIcon } from 'common/icons/social/twitter.svg';
import { ReactComponent as TelegramIcon } from 'common/icons/social/telegram.svg';*/
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const { t } = useTranslation();
  return (
    <>
      <footer className="footer">
        <div className='footer-content'>
          <div className='footer-side'>
            <Link to='/' className='footer-logo'>
              <FooterLogo />
            </Link>
            <span className='footer-text'>{t('footer.description')}</span>
            {/*<div className='footer-social'>
              <a target='_blank' href=''><FacebookIcon className='svg-black svg-fill' /></a>
              <a target='_blank' href=''><InstagramIcon className='svg-black svg-fill' /></a>
              <a target='_blank' href=''><TwitterIcon className='svg-black svg-fill' /></a>
              <a target='_blank' href=''><TelegramIcon className='svg-black svg-fill' /></a>
            </div>*/}
          </div>
          <div className='footer-side'>
            <div className='footer-nav'>
              <Link to='/about'>{t('footer.about')}</Link>
              <Link to='/archive'>{t('footer.archive')}</Link>
              <Link to='/contacts'>{t('footer.contact')}</Link>
              <Link to='/privacy_policy'>{t('footer.privacy')}</Link>
              <a className="astin-logo" href="https://astin.org">{t('footer.astin')}&reg;</a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
