import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)

	.init({
		fallbackLng: "UK",
		debug: true,
		detection: {
			order: ["queryString", "cookie", "localStorage"],
			cache: ["cookie"],
			lookupLocalStorage: "i18nextLng"
		},
		interpolation: {
			escapeValue: false
		}
	});