import Footer from 'common/components/Footer';
import Header from 'common/components/Header';
import React from 'react';
import { Outlet } from 'react-router-dom';

export default function BaseLayout() {
  return (
        <>
            <div className='wrapper'>
                <Header />
                <div className='content'>
                    <Outlet />
                </div>
                <Footer />
            </div>
        </>
  );
}