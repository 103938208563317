import React from 'react';
import './contactsPage.scss';
import { ReactComponent as PhoneIcon } from 'common/icons/phone.svg';
import { ReactComponent as EmailIcon } from 'common/icons/mail.svg';
import { ReactComponent as LocationIcon } from 'common/icons/location.svg';
import JournalSection from 'common/components/JournalSection';
import TempMap from 'common/images/temp-map.png';
import { useTranslation } from 'react-i18next';

export default function ContactsPage() {
  const { t } = useTranslation();
  return (
        <div className="contacts-page">
            <div className="contacts-content">
                <div className='contacts-info-wrapper'>
                    <h1>{t('contacts.title')}</h1>
                    <div className='contacts-info-line'>
                        <PhoneIcon className='svg-black' />
                        <div className='contacts-info-text'>
                            <h3 className='contacts-info-title'>{t('contacts.phoneTitle')}</h3>
                          <span className="contacts-info-subtext">
                              <a href="callto:+49(0)17621131623">+49(0)17621131623</a>
                              </span>
                        </div>
                    </div>
                  <div className="contacts-info-line">
                    <EmailIcon className='svg-black' />
                        <div className='contacts-info-text'>
                            <h3 className='contacts-info-title'>{t('contacts.emailTitle')}</h3>
                            <span className='contacts-info-subtext'>
                                <a href="mailto:ukrainotchkainfo@gmail.com">
                                    ukrainotchkainfo@gmail.com
                                </a>
                            </span>
                        </div>
                    </div>
                    <div className='contacts-info-line'>
                        <LocationIcon className='svg-black' />
                        <div className='contacts-info-text'>
                            <h3 className='contacts-info-title'>{t('contacts.addressTitle')}</h3>
                            <span className='contacts-info-subtext'>Meineckestraße 52b, 40474 Düsseldorf</span>
                        </div>
                    </div>
                </div>
                <div className='contacts-map-wrapper'>
                    <img src={TempMap} alt='map' />
                </div>
            </div>
            <div className='post-separator about-separator' />
            <JournalSection />
        </div>
  );
}