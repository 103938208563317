import axios from 'axios';
import { AxiosRequestConfig } from 'axios';
import { useEffect, useState } from 'react';

type ConfigTypes = AxiosRequestConfig & {
  useEnvLink?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateList?: any[];
};

export default function useFetch(url: string, options:ConfigTypes) {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setloading] = useState(false);
  const { useEnvLink } = options;
  const envLink = useEnvLink ? process.env.REACT_APP_API_URL : '';
  const fetchData = async () => {
    setloading(true);
    try {
      const res = await axios(envLink + url, options);
      if ( res.status === 200) {
        setResponse(res.data);
        setloading(false);
      } else {
        setError(res.data);
        setloading(false);
      }
    } catch (errorInner) {
      setError(errorInner);
    }
  };

  useEffect(() => {
    fetchData();
  }, options.updateList || []);

  return { response, error, loading };
}