import React from 'react';
import DotSeparator from 'common/components/DotSeparator';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PreviewPostProps } from '../../types/PostProps';

export const BigPost = ({ id, title, text, category, date, image }: PreviewPostProps) => {
  const { t } = useTranslation();
  return (
    <div className='main-post'>
      <div className='main-post-image-wrapper'>
        <img src={image} alt='temp-post' />
      </div>
      <div className='time-wrapper'>
        <span className='time-subtext'>{category}</span>
        <DotSeparator />
        <span className='time-subtext'>{t('intlDateTime', {
          val: new Date(date),
          formatParams: {
            val: {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            },
          },
        })}</span>
      </div>
      <h1 className='main-post-title'>{title}</h1>
      <span className='main-post-text'>{text}</span>
      <Link to={`/article/${id}`} className='main-post-button'>{t('main.readMore')}</Link>
    </div>
  );
};
