const lightThemeData = {
  colors: {
    primaryColor: '#AB1D1D',
    secondaryColor: '#ECECEC',
    black: '#0B0B0B',
    primaryTextColor: '#0B0B0B',
    secondaryTextColor: '#ECECEC',
    error: '#F80E38',
    success: '#2DF90B',
    gray: '#0B0B0B80',
    yellow: '#fbe51f',
    primaryHoverColor: '#4563d9',
    primaryHoverTextColor: '#ECECEC',
    secondaryHoverColor: 'rgba(86, 120, 255, 0.1)',
    secondaryHoverTextColor: '#0B0B0B',
    backgroundColor: '#ECECEC',
    secondaryBackgroundColor: '#E6DDDD',
    white: '#F7F7F7',
  },
  palette: {
    text: {
      primary: '#0B0B0B',
      secondary: '#0B0B0B80',
    },
  },
  typography: {
    'fontFamily': 'Mulish',
    'fontSize': 14,
    'letterSpacing': 1,
    'color': '#0B0B0B',
  },
};

export default lightThemeData;