import { useTranslation } from 'react-i18next';
import useFetch from 'common/hooks/useFetch';

export const useFetchCategories = () => {
  const { i18n } = useTranslation();
  const { response, loading, error } = useFetch('api/nomenclature/get-all-categories',
    {
      method: 'GET',
      useEnvLink: true,
      params: {
        lang: i18n.language,
      },
      updateList: [i18n.language],
    },
  );
  return {
    categories: response?.nomenclatureList || [],
    loading,
    error,
  };
};
