import React, { useState } from 'react';
import './header.scss';
import { ReactComponent as Logo } from 'common/icons/branding/Logo.svg';
/*import { ReactComponent as SearchIcon } from 'common/icons/search.svg';
import { ReactComponent as FacebookIcon } from 'common/icons/social/facebook.svg';
import { ReactComponent as InstagramIcon } from 'common/icons/social/instagram.svg';
import { ReactComponent as TwitterIcon } from 'common/icons/social/twitter.svg';
import { ReactComponent as TelegramIcon } from 'common/icons/social/telegram.svg';*/
import { ReactComponent as CrossIcon } from 'common/icons/cross.svg';
import styled from '@emotion/styled';
import Button from '../Button';
import { Modal } from '@mui/material';
import MagazineImage from 'common/images/magazine.png';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import useFetch from 'common/hooks/useFetch';
import { lngs as defaultLngs } from 'common/data/lngs';

const LanguageSeparator = styled.div`
    width: 4px;
    height: 4px;
    border-radius: 1px;
    background: ${props => props.theme.colors.primaryColor};
`;

const useGetLngs = () => {
  const { response, loading, error } = useFetch('api/nomenclature/get-all-languages', {
    method: 'GET',
    useEnvLink: true,
  });
  const defLangs = Object.values(defaultLngs).map((v) => ({ id: v.code, name: v.nativeName }));

  const lngs = response?.nomenclatureList || defLangs;
  return {
    lngs,
    loading,
    error,
  };
};

type LngsType = {
  id: string;
  name: string;
};

export default function Header() {
  const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false);
  const [donateModalOpen, setDonateModalOpen] = useState(false);
  const [emailValue, setEmailValue] = useState('');
  const [emailError, setEmailError] = useState(false);
  const { lngs }:{ lngs:LngsType[] } = useGetLngs();
  const { t, i18n } = useTranslation();
  const checkEmail = (value: string) => {
    const reg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (!reg.test(value)) {
      setEmailError(true);
      return false;
    } else {
      setEmailError(false);
      return true;
    }
  };
  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (emailError) {
      checkEmail(e.target.value);
    }
    setEmailValue(e.target.value);
  };
  const sendEmail = async (email: string) => {
    const link = process.env.REACT_APP_API_URL + 'api/email/add-email';
    // axios post
    const formData = new FormData();
    formData.append('email', email);
    axios.post(link, formData)
      .then(function (response) {
        console.log(response);
        if (response.data.statusCode === 200) {
          setEmailValue('');
          setSubscriptionModalOpen(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

  };
  const onSendEmail = () => {
    const checkResult = checkEmail(emailValue);
    if (checkResult) {
      sendEmail(emailValue);
    }
  };

  console.log(lngs);

  return (
        <>
            <Modal
                open={donateModalOpen}
                onClose={() => { setDonateModalOpen(false); }}
            >
                <div onClick={() => { setDonateModalOpen(false); }} className='subscription-modal-wrapper'>
                    <div onClick={(e) => { e.stopPropagation(); }} className='donate-modal'>
                        <div className='subscription-modal-content'>
                            <h3 className='subscription-modal-title'>{t('header.modal.IBAN')}</h3>
                            <div className='subscription-modal-subtitle'>Deutsche Skatbank</div>
                            <div className='subscription-modal-subtitle'>IBAN: DE71 8306 5408 0005 3015 64</div>
                            <div className='subscription-modal-subtitle'>BIC: GENO DEF1 SLR</div>
                            <h3 className='subscription-modal-title'>{t('header.modal.donatepaypal')}</h3>
                            <span className='subscription-modal-subtitle'>ukrainotchkainfo@gmail.com</span>
                        </div>
                        <CrossIcon className='svg-black svg-fill svg-button close-cross' onClick={() => { setDonateModalOpen(false); }} />
                    </div>
                </div>
            </Modal>
            <Modal
                open={subscriptionModalOpen}
                onClose={() => { setSubscriptionModalOpen(false); }}
            >
                <div onClick={() => { setSubscriptionModalOpen(false); }} className='subscription-modal-wrapper'>
                    <div onClick={(e) => { e.stopPropagation(); }} className='subscription-modal'>
                        <img className="girl-pic" src={MagazineImage} alt='' />
                        <div className='subscription-modal-content'>
                            <h1 className='subscription-modal-title'>{t('header.modal.keepupdated')}</h1>
                            <span className='subscription-modal-subtitle'>{t('header.modal.subscribe')}</span>
                            <input value={emailValue} onChange={onEmailChange} type='email' placeholder='Email' className={emailError ? 'subscription-modal-input error' : 'subscription-modal-input'} />
                            <Button onClick={onSendEmail} className='subscription-modal-button'>{t('header.modal.send')}</Button>
                        </div>
                        <CrossIcon className='svg-black svg-fill svg-button close-cross' onClick={() => { setSubscriptionModalOpen(false); }} />
                    </div>
                </div>
            </Modal>
            <header className='header'>
                <div className='header-content'>
                    <Link to='/'><Logo /></Link>
                    {/*<div className='header-search-wrapper'>
                        <SearchIcon className='svg-black svg-fill svg-button' />
                        <input type='text' placeholder={t('header.search') + '...'} />
                    </div>*/}
                    <div className='header-right-wrapper'>
                        {/*<div className='header-right-button-wrapper'>
                            <a target='_blank' href='' className='header-right-button'>
                                <FacebookIcon className='svg-black svg-fill' />
                            </a>
                            <a target='_blank' href='' className='header-right-button'>
                                <InstagramIcon className='svg-black svg-fill' />
                            </a>
                            <a target='_blank' href='' className='header-right-button'>
                                <TwitterIcon className='svg-black svg-fill' />
                            </a>
                            <a target='_blank' href='' className='header-right-button'>
                                <TelegramIcon className='svg-black svg-fill' />
                            </a>
                        </div>*/}
                        <div className='header-language-wrapper'>
                            {/*<span className='header-language active'>UA</span>
                            <LanguageSeparator />
                            <span className='header-language'>DE</span>
                            separator after each language but last
                            */}
                            {lngs && lngs.map((lng, index) => {

                              const separator = index !== lngs.length - 1 ? <LanguageSeparator /> : null;
                              return (
                                    <React.Fragment key={index}>
                                        <span
                                          onClick={() => i18n.changeLanguage(lng.id)}
                                          className={lng.id === i18n.language ? 'header-language active' : 'header-language'}
                                        >{lng.name}</span>
                                        {separator}
                                    </React.Fragment>
                              );
                            })}
                        </div>
                      <div className="header-button-container">
                        <Button onClick={() => { setDonateModalOpen(true); }} className='header-button-donate'>
                          {t('header.donateButton')}
                        </Button>
                        {/*<Button onClick={() => { setSubscriptionModalOpen(true); }} className='header-button'>
                          {t('header.subButton')}
                        </Button>*/}
                      </div>
                    </div>
                </div>
            </header>
        </>
  );
}
