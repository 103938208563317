import React/*, { useState }*/ from 'react';
import Magazine from 'common/images/magazine.png';
/*
import { ReactComponent as ArrowRight } from 'common/icons/arrow-right.svg';
*/
import Button from 'common/components/Button';
/*import axios from 'axios';*/
import { Trans, useTranslation } from 'react-i18next';
import JournalPdf from 'common/misc/Ukrainotchka.pdf';

export default function JournalSection() {
/*
  const [emailValue, setEmailValue] = useState('');
*/
  /*const [emailError, setEmailError] = useState(false);*/
  const { t } = useTranslation();
  /*  const checkEmail = (value: string) => {
    const reg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (!reg.test(value)) {
      setEmailError(true);
      return false;
    } else {
      setEmailError(false);
      return true;
    }
  };*/
  /*  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (emailError) {
      checkEmail(e.target.value);
    }
    setEmailValue(e.target.value);
  };*/
  /*  const sendEmail = async (email: string) => {
    const link = process.env.REACT_APP_API_URL + 'api/email/add-email';
    // axios post
    const formData = new FormData();
    formData.append('email', email);
    axios.post(link, formData)
      .then(function (response) {
        console.log(response);
        if (response.data.statusCode === 200) {
          setEmailValue('');

        }
      })
      .catch(function (error) {
        console.log(error);
      });

  };*/
  /*  const onSendEmail = () => {
    const checkResult = checkEmail(emailValue);
    if (checkResult) {
      sendEmail(emailValue);
    }
  };*/
  return (
        <section className='subscribe-section'>
        <div className='subscribe-journal-wrapper'>
          <div className='subscribe-journal-img-wrapper'>
            <img src={Magazine} alt='journal' />
          </div>
          <div className='subscribe-journal-content'>
            <h3 className='subscribe-journal-title'>
                <Trans i18nKey='subscribe.journal'>
                Журнал <a className='plain-link' href='/'>Ukrainotchka</a>
                </Trans>
            </h3>
            <Button
              className='subscribe-journal-button'
              onClick={() => window.open(JournalPdf)}
            >{t('subscribe.journalButton')}
            </Button>
          </div>
        </div>
        {/*<div className='subscribe-form-wrapper'>
          <div className='subscribe-form-text-wrapper'>
            <h3 className='subscribe-form-title'>{t('subscribe.formTitle')}</h3>
            <span className='subscribe-form-text'>{t('subscribe.formSubtitle')}</span>
          </div>
          <div className='subscribe-form'>
            <input value={emailValue} onChange={onEmailChange} className={`subscribe-form-input${emailError ? ' error' : ''}`} type='email' placeholder={t('subscribe.formInput')} />
            <button onClick={onSendEmail} className='subscribe-form-button'>
              <ArrowRight className='svg-black' />
            </button>
          </div>
        </div>*/}
      </section>
  );
}
