import React from 'react';
import DotSeparator from 'common/components/DotSeparator';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { SmallPostProps } from 'common/types/PostProps';

export const CarouselItem = ({ id, title, category, date, image }: SmallPostProps) => {
  const { t } = useTranslation();
  return (

    <Link to={`/article/${id}`}>
      <div className='post-carousel-item'>
        <img src={image} alt='temp-post' />
        <div className='small-post-time-wrapper'>
          <span className='main-post-subtext'>{category}</span>
          <DotSeparator />
          <span className='main-post-subtext'>
            {t('intlDateTime', {
              val: new Date(date),
              formatParams: {
                val: {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                },
              },
            })}
          </span>
        </div>
        <h4 className='small-post-title'>{title}</h4>
      </div>
    </Link>
  );
};
