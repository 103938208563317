import React from 'react';
import DotSeparator from 'common/components/DotSeparator';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { SmallPostProps } from '../../types/PostProps';

export const SmallPost = ({ id, title, category, date, image }: SmallPostProps) => {
  const { t } = useTranslation();
  return (
    <Link to={`/article/${id}`} className='small-post'>
      <div className='small-post'>
        <div className='small-post-image-wrapper'>
          <img src={image} alt='temp-post' />
        </div>
        <div className='small-post-content'>
          <div className='small-post-time-wrapper'>
            <span className='small-post-subtext'>{category}</span>
            <DotSeparator />
            <span className='small-post-subtext'>
              {t('intlDateTime', {
                val: new Date(date),
                formatParams: {
                  val: {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  },
                },
              })}
            </span>
          </div>
          <span className='small-post-title'>{title}</span>
        </div>
      </div>
    </Link>
  );
};
