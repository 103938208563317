import React, { useCallback, useRef, useState } from 'react';
import './mainPage.scss';
import { useTranslation } from 'react-i18next';
import 'swiper/css';
// import { Controller } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ReactComponent as ArrowLeft } from 'common/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'common/icons/arrow-right.svg';
import JournalSection from 'common/components/JournalSection';
import { CarouselItem } from 'common/components/CarouselItem';
import { useFetchCategories } from 'common/hooks/useFetchCategories';
import { useFetchPostsByCategory } from 'common/hooks/useFetchPostsByCategory';
import { useFetchPopularPosts } from 'common/hooks/useFetchPopularPosts';
import { useFetchPreviewPost } from 'common/hooks/useFetchPreviewPost';
import { categories } from '../../data/categories';
import { BigPost } from 'common/components/BigPost';
import { SmallPost } from 'common/components/SmallPost';
import { posts } from 'common/data/posts';
import { mockBigPost } from 'common/data/mockBigPost';
import { CategoryLineProps } from 'common/types/CategoryLineProps';

const CategoryLine = ({ labelKey, label, id, onClick, active }: CategoryLineProps) => {
  const { t } = useTranslation();
  return (
    <button name={id} onClick={onClick} className={`category-line-button${active ? ' active' : ''}`}>{label ? label : t(labelKey)}</button>
  );
};

export default function MainPage() {
  const [activeCategory, setActiveCategory] = useState<string>('psychology');
  const swiperRef = useRef(null);
  const { t } = useTranslation();
  const handleCategoryClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setActiveCategory(e.currentTarget.name);
  };
  const handlePrev = useCallback(() => {
    if (!swiperRef.current) return;
    swiperRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!swiperRef.current) return;
    swiperRef.current.swiper.slideNext();
  }, []);
  const { categories: categoriesResponse, loading:categoriesLoading, error:categoriesError } = useFetchCategories();
  const { response:previewPostResponse, loading:previewPostLoading, error:previewPostError } = useFetchPreviewPost();
  const { response:popularPostsResponse, loading:popularPostsLoading, error:popularPostsError } = useFetchPopularPosts();
  const { response:postsResponse, loading:postsLoading, error:postsError } = useFetchPostsByCategory(activeCategory);
  console.log(previewPostResponse);
  return (
    <div className='main-page-wrapper'>
      <section className='popular-section'>
        {
          (previewPostLoading || previewPostError) ?
            <BigPost {...{ ...mockBigPost, ...{ title: t('mockBigPost.title'), text: t('mockBigPost.text'), category: t('mockBigPost.category') } }} /> : <BigPost {...previewPostResponse} />
        }
        <div className='popular-posts'>
          <h2 className='popular-posts-title'>{t('main.popular')}</h2>
          { (popularPostsLoading || popularPostsError) ? posts.map((post, index) => (
            <SmallPost key={index} {...{ ...post, ...{ title: t(`mockPosts.${post.id}.title`), category: t(`mockPosts.${post.id}.category`) } }} />
          )) : popularPostsResponse?.map((post, index) => (
            <SmallPost key={index} {...post} />
          ))}
        </div>
      </section>
      <section className='post-section'>
        <div className='category-line'>
          {(categoriesLoading || categoriesError) ? categories.map((category, index) => (
            <CategoryLine onClick={handleCategoryClick} active={category.id === activeCategory} key={index} {...category} />
          )) :
            categoriesResponse.map((category, index) => (
              <CategoryLine onClick={handleCategoryClick} active={category.id === activeCategory} key={index} label={category.name} id={category.id} />
            ))
          }
        </div>
        <div className='post-carousel-wrapper'>
          <button
            className='post-carousel-button post-carousel-button-prev'
            onClick={handlePrev}
          >
            <ArrowLeft className='svg-black' />
          </button>
          <Swiper
            slidesPerView={1}
            spaceBetween={16}
            breakpoints={{
              1440: {
                slidesPerView: 3,
              },
              920: {
                slidesPerView: 2,
              },
              640: {
                slidesPerView: 1,
              },
            }}
            ref={swiperRef}
            centeredSlides={false}
            centerInsufficientSlides
            className='post-carousel'
            updateOnWindowResize
          >
            {
              (postsLoading || postsError) ? posts.map((post, index) => (
                <SwiperSlide key={index} className='post-carousel-slide-wrapper'>
                  <CarouselItem {...{ ...post, ...{ title: t(`mockPosts.${post.id}.title`), category: t(`mockPosts.${post.id}.category`) } }} />
                </SwiperSlide>
              )) : postsResponse?.map((post, index) => (
                <SwiperSlide key={index} className='post-carousel-slide-wrapper'>
                  <CarouselItem {...post} />
                </SwiperSlide>
              ))
            }
          </Swiper>
          <button
            className='post-carousel-button post-carousel-button-next'
            onClick={handleNext}
          >
            <ArrowRight className='svg-black' />
          </button>
        </div>
        <div className='post-carousel-button-wrapper'>
          <button className='main-post-button'>{t('main.more')}</button>
        </div>
      </section>
      <JournalSection />
    </div>
  );
}
