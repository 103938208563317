import TempPost2 from 'common/images/temp-post2.jpg';
//import TempPost3 from 'common/images/temp-post3.jpg';
import TempPost3 from 'common/images/temp-post3.jpg';
import TempPost5 from 'common/images/temp-post5.jpg';
import TempPost6 from 'common/images/temp-post6.jpg';
import TempPost7 from 'common/images/temp-post7.jpg';
import { SmallPostProps } from 'common/types/PostProps';

export const posts: SmallPostProps[] = [
  {
    id: '1',
    title: '5 чітких кроків на шляху до ненасильницької адаптації від психолога',
    category: 'Психологія',
    date: '2023-11-15',
    image: TempPost6,
  },
  {
    id: '2',
    title: 'Вони смогли — і ти зможеш: інтерв’ю з успішними українцями',
    category: 'Інтерв’ю',
    date: '2023-06-01',
    image: TempPost2,
  },
  {
    id: '3',
    title: 'Виховання дітей за законами Євросоюзу',
    category: 'Діти',
    date: '2023-10-11',
    image: TempPost3,
  },
  {
    id: '4',
    title: 'Робоче середовище: пошук роботи та кар\'єрні можливості',
    category: 'Робота',
    date: '2023-09-21',
    image: TempPost5,
  },
  {
    id: '5',
    title: '6 податкових классів у Німеччині',
    category: 'Гроші',
    date: '2023-08-15',
    image: TempPost7,
  },
];
