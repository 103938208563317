import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import JournalSection from 'common/components/JournalSection';
import { ReactComponent as FacebookIcon } from 'common/icons/social/facebook.svg';
import { ReactComponent as InstagramIcon } from 'common/icons/social/instagram.svg';
import { ReactComponent as TwitterIcon } from 'common/icons/social/twitter.svg';
import { ReactComponent as TelegramIcon } from 'common/icons/social/telegram.svg';
import Team from 'common/images/team.png';




export default function AboutPage() {
  
  // get post id from url
  const { id } = useParams();
  console.log(id);
  const { t } = useTranslation();
  // TODO: get post data from api

  return (
    <div className='post-page'>
      <div className='post-title about-title'>
        <h1>{t('about.title')}</h1>
      </div>
      <div className='post-image-wrapper'>
        <img src={Team} alt='post' />
      </div>
      <span className='post-subtext'>
        {t('post.source')}: {t('about.source')}
      </span>
      <div className='post-content-wrapper'>
        <div className='post-socials-wrapper'>
          <a target='_blank' href=''><FacebookIcon className='svg-black svg-fill' /></a>
          <a target='_blank' href=''><InstagramIcon className='svg-black svg-fill' /></a>
          <a target='_blank' href=''><TwitterIcon className='svg-black svg-fill' /></a>
          <a target='_blank' href=''><TelegramIcon className='svg-black svg-fill' /></a>
        </div>
        <div className='post-content'>
          <ReactMarkdown
            className='post-text'
          >
            {t('about.text')}
          </ReactMarkdown>
        </div>
        <div className='post-socials-wrapper' />
      </div>
      <div className='post-separator about-separator' />
      
      <JournalSection />
    </div>
  );
}